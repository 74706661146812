exports.components = {
  "component---src-pages-bar-js": () => import("./../../../src/pages/bar.js" /* webpackChunkName: "component---src-pages-bar-js" */),
  "component---src-pages-chef-table-js": () => import("./../../../src/pages/chef-table.js" /* webpackChunkName: "component---src-pages-chef-table-js" */),
  "component---src-pages-concept-js": () => import("./../../../src/pages/concept.js" /* webpackChunkName: "component---src-pages-concept-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-event-subscription-js": () => import("./../../../src/pages/event-subscription.js" /* webpackChunkName: "component---src-pages-event-subscription-js" */),
  "component---src-pages-food-js": () => import("./../../../src/pages/food.js" /* webpackChunkName: "component---src-pages-food-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-private-dining-js": () => import("./../../../src/pages/private-dining.js" /* webpackChunkName: "component---src-pages-private-dining-js" */),
  "component---src-pages-reservation-js": () => import("./../../../src/pages/reservation.js" /* webpackChunkName: "component---src-pages-reservation-js" */),
  "component---src-pages-winecellar-js": () => import("./../../../src/pages/winecellar.js" /* webpackChunkName: "component---src-pages-winecellar-js" */),
  "component---src-templates-apply-js": () => import("./../../../src/templates/apply.js" /* webpackChunkName: "component---src-templates-apply-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-single-job-js": () => import("./../../../src/templates/single-job.js" /* webpackChunkName: "component---src-templates-single-job-js" */)
}

